<template>
    <Loader v-if="loading" />
    <div v-else>
        <Chat :sessionId="visio.session_id" />
        <div class="card card-custom gutter-b flex-row align-items-center justify-content-between p-5 mt-5">
            <div class="w-25">
                <router-link :to="tuteur ? prevRoute : `/classe/${classUserId}`">
                    <span class="btn btn-outline-danger btn-md">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                        </span>
                        {{ !mobile() ? 'Retour' : '' }}
                    </span>
                </router-link>
            </div>
            <div class="d-flex flex-column align-items-center">
                <h1 class="ml-5 mb-0 align-self-center w-100">{{ classroom.classe.title }}</h1>
            </div>
            <div v-if="!mobile()" class="w-25"></div>
        </div>
        <div class="card card-custom gutter-b p-5 align-items-center w-100">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <h3>{{ visio.name }}</h3>
                <h4>{{ formatDate(visio.start) }}</h4>
                <div v-if="!visioUser.signature" class="d-flex flex-column align-items-center mt-5">
                    <h6>Afin d'accéder à la videoconférence, veuillez utiliser le cadre ci-dessous pour signer la feuille de présence.</h6>
                    <div class="d-flex mt-5">
                        <canvas
                            ref="signatureCanvas"
                            class="signature-pad"
                            @mousedown="startDrawing"
                            @mouseleave="stopDrawing"
                            @mousemove="draw"
                            @mouseup="stopDrawing"
                        ></canvas>
                        <div class="ml-5 d-flex flex-column justify-content-around">
                            <b-button
                                v-b-tooltip.hover.top="'Enregistrer et rejoindre la vidéoconférence'"
                                :disabled="!signature || sending"
                                class="btn btn-icon btn-circle d-flex justify-content-center align-items-center"
                                pill
                                variant="primary"
                                @click="saveSignature"
                            >
                                <b-spinner v-if="sending" label="Spinning" small variant="light"></b-spinner>
                                <span v-else class="svg-icon svg-icon-md svg-icon-primary d-flex justify-content-center align-items-center">
                                    <i class="fas fa-save"></i>
                                </span>
                            </b-button>
                            <b-button
                                v-b-tooltip.hover.top="'Effacer la signature'"
                                :disabled="!signature || sending"
                                class="btn btn-icon btn-circle d-flex justify-content-center align-items-center"
                                pill
                                variant="primary"
                                @click="deleteDrawing()"
                            >
                                <span class="svg-icon svg-icon-md svg-icon-primary d-flex justify-content-center align-items-center">
                                    <i class="fas fa-trash"></i>
                                </span>
                            </b-button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex flex-column">
                        <b-button v-if="tuteur" class="mt-2" pill variant="primary" @click="toggleModal(visio.id)">
                            {{ visio.is_active ? 'Mettre fin à' : 'Activer' }} la vidéoconférence
                        </b-button>
                        <b-button v-if="visio.is_active" :href="visio.url" class="mt-2" pill target="_blank" variant="primary">
                            Cliquez pour accéder à la vidéoconférence
                        </b-button>
                        <b-modal
                            id="start-classroom-modal"
                            title="Démarrer la session"
                            ok-title="Confirmer"
                            cancel-title="Annuler"
                            @ok="startClassroom()"
                        >
                            <p>Êtes vous sûr de vouloir démarrer la session ?</p>
                        </b-modal>
                        <b-button
                            v-if="tuteur"
                            pill
                            variant="outline-primary"
                            class="d-flex justify-content-center align-items-center mt-2"
                            @click="$bvModal.show('start-classroom-modal')"
                            :disabled="classroom.started === 1"
                        >
                            <!-- <b-icon v-if="!activatingSession" icon="play" class="mr-2"></b-icon> -->
                            <!-- <b-spinner v-else small variant="light" class="mr-3"></b-spinner> -->
                            {{ classroom.started === 1 ? 'Session démarrée' : 'Démarrer la session' }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirmation-modal" title="Confirmation" ok-title="Confirmer" cancel-title="Annuler" @ok="toggleVideoconference()">
            <p>Êtes-vous sûr de vouloir {{ visio.is_active ? 'mettre fin à' : 'activer' }} cette vidéoconférence ?</p>
        </b-modal>
        <div v-if="tuteur && visioUser.signature" class="card card-custom gutter-b p-5 align-items-center w-100">
            <h4>Liste des signatures</h4>
            <b-table
                :busy="loadingUsers"
                :fields="fields"
                :items="users"
                class="mx-0 p-0 mb-5"
                hover
                no-border-collapse
                responsive
                style="vertical-align: middle; text-align: center"
            >
                <template v-slot:cell(is_present)="row">
                    <p class="m-0">{{ row.item.is_present ? 'Oui' : 'Non' }}</p>
                </template>
                <template #cell(signature)="row">
                    <img v-if="row.item.signature" :src="row.item.signatureUrl" alt="Signature du stagiaire" style="height: 5rem" />
                    <p v-else class="m-0">Pas de signature</p>
                </template>
                <template v-slot:cell(action)="row">
                    <b-button
                        v-if="row.item.signature && row.item.user_id !== $store.getters.currentUser.id"
                        v-b-tooltip.hover.top="'Supprimer la signature'"
                        class="btn btn-icon btn-circle d-flex justify-content-center align-items-center"
                        pill
                        variant="danger"
                        @click="
                            $bvModal.show('deleteSignatureModal');
                            userToDelete = row.item;
                        "
                    >
                        <span class="svg-icon svg-icon-md svg-icon-danger d-flex justify-content-center align-items-center">
                            <i class="fas fa-trash"></i>
                        </span>
                    </b-button>
                </template>
                <template #empty="scope"> Aucun stagiaire inscrit. </template>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-3">Chargement...</strong>
                    </div>
                </template>
            </b-table>
            <!--            create modal before delete user signature-->
            <b-modal
                id="deleteSignatureModal"
                :title="
                    userToDelete
                        ? 'Supprimer la signature de ' + userToDelete.lastname + ' ' + userToDelete.firstname + ' ?'
                        : 'Supprimer la signature ?'
                "
            >
                <p>Êtes-vous sûr de vouloir supprimer la signature de ce stagiaire ?</p>
                <template #modal-footer>
                    <b-button
                        variant="secondary"
                        @click="
                            userToDelete = null;
                            $bvModal.hide('deleteSignatureModal');
                        "
                        >Non</b-button
                    >
                    <b-button variant="danger" @click="deleteSignature(userToDelete.id)">Oui</b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>
<script>
import { ACTIVATE_CLASSROOM, GET_VISIO } from '../../core/services/store/api/videoconference.service';
import Loader from '../content/Loader.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { GET_CLASSROOM } from '../../core/services/store/api/classroom.service';
import { GET_CLASSEUSER_BY_USERID_AND_SESSIONID } from '@/core/services/store/api/classe_users.service';
import Chat from '@/view/content/components/Chat.vue';

export default {
    name: 'Visios',
    components: { Loader, Chat },
    data() {
        return {
            loading: true,
            tuteur: false,
            classroom: {},
            classUserId: null,
            visio: {},
            visioUser: {},
            context: null,
            drawing: false,
            signature: false,
            signatureFile: null,
            error: false,
            sending: false,
            prevRoute: null,
            users: [],
            loadingUsers: true,
            fields: [
                { key: 'lastname', label: 'Nom', sortable: true, tdClass: 'align-middle' },
                { key: 'firstname', label: 'Prénom', sortable: true, tdClass: 'align-middle' },
                { key: 'is_present', label: 'Présent', sortable: true, tdClass: 'align-middle' },
                { key: 'signature', label: 'Signature', sortable: false, tdClass: 'align-middle' },
                { key: 'action', label: '', sortable: false, tdClass: 'align-middle' }
            ],
            userToDelete: null,
            wait: false,
            selected: null,
            displayVisios: {}
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },
    async mounted() {
        if (!this.$route.params.visioId) {
            this.$router.push({ name: 'dashboard' });
        } else {
            await this.checkTuteur([1]);
            await this.getVisioUser();
            await this.getVisio();

            setInterval(this.getSignatures, 10000);
        }
    },
    methods: {
        toggleModal(id) {
            this.$bvModal.show('confirmation-modal');
            this.selected = id;
        },
        toggleVideoconference() {
            //call api pour activer la visio
            this.$store
                .dispatch(ACTIVATE_CLASSROOM, this.selected)
                .then((response) => {
                    this.visio = response.data;
                })
                .finally(() => {
                    this.selected = null;
                    this.$bvModal.hide('confirmation-modal');
                    if (this.visioUser.signature && this.tuteur && this.visio.is_active) {
                        window.open(this.visio.url, '_blank');
                    }
                });
        },
        getSignatures() {
            if (this.tuteur) {
                ApiService.query(process.env.VUE_APP_API_URL + '/academy/videoconference/user/visio/' + this.$route.params.visioId).then(
                    (response) => {
                        this.users = response.data;
                        if (this.loadingUsers) {
                            this.loadingUsers = false;
                        }
                    }
                );
            } else {
                if (this.visioUser.signature && !this.wait) {
                    this.wait = true;
                    ApiService.query(
                        process.env.VUE_APP_API_URL +
                            '/academy/videoconference/' +
                            this.$route.params.visioId +
                            '/user/' +
                            this.$store.getters.currentUser.id
                    ).then((response) => {
                        this.visioUser = response.data;
                        this.wait = false;
                        if (!this.visioUser.signature) {
                            this.$nextTick(() => {
                                this.initializeCanvas();
                            });
                        }
                    });
                }
            }
        },
        deleteSignature(id) {
            ApiService.put(process.env.VUE_APP_API_URL + '/academy/videoconference/user/' + id, { is_present: 0, signature: null })
                .then(() => {
                    this.$bvToast.toast('La signature a bien été supprimée.', {
                        title: 'Succès',
                        variant: 'success',
                        solid: true
                    });
                    this.getSignatures();
                    this.userToDelete = null;
                    this.$bvModal.hide('deleteSignatureModal');
                })
                .catch(() => {
                    this.$bvToast.toast('Une erreur est survenue lors de la suppression de la signature.', {
                        title: 'Erreur',
                        variant: 'danger',
                        solid: true
                    });
                    this.userToDelete = null;
                    this.$bvModal.hide('deleteSignatureModal');
                });
        },
        mobile() {
            return window.innerWidth < 768;
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY' + ' à ' + 'HH:mm');
            }
        },
        async getVisio() {
            this.$store
                .dispatch(GET_VISIO, this.$route.params.visioId)
                .then((response) => {
                    this.visio = response.data;
                    if (!this.visio.is_active && !this.tuteur) {
                        this.$router.push({ name: 'classroom-visio-waiting', params: { visioId: this.visio.id } });
                    }

                    this.$store.dispatch(GET_CLASSROOM, this.visio.session_id).then(async (response) => {
                        //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                        if (this.tuteur) {
                            if (response.data.tuteur_id !== this.$store.getters.currentUser.id) {
                                this.$router.push({ name: 'dashboard' });
                            }
                        }
                        this.classroom = response.data;
                        this.loading = false;
                        if (!this.tuteur) {
                            await this.$store
                                .dispatch(GET_CLASSEUSER_BY_USERID_AND_SESSIONID, {
                                    userId: this.currentUser.id,
                                    sessionId: this.classroom.id
                                })
                                .then((response) => {
                                    this.classUserId = response.data.id;
                                });
                        }
                        //wait next tick
                        if (!this.visioUser.signature) {
                            this.$nextTick(() => {
                                this.initializeCanvas();
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$router.push({ name: 'dashboard' });
                });
        },
        async getVisioUser() {
            await ApiService.query(
                process.env.VUE_APP_API_URL +
                    '/academy/videoconference/' +
                    this.$route.params.visioId +
                    '/user/' +
                    this.$store.getters.currentUser.id
            ).then((response) => {
                this.visioUser = response.data;
            });
        },
        async checkTuteur(rightIds) {
            if (
                this.$store.getters.currentUser &&
                this.$store.getters.currentUser.rights &&
                this.$store.getters.currentUser.rights.academy
            ) {
                this.$store.getters.currentUser.rights.academy.forEach((right) => {
                    if (rightIds.includes(right.right_id)) {
                        this.tuteur = true;
                    }
                });
            }
        },
        initializeCanvas() {
            const canvas = this.$refs.signatureCanvas;
            //if mobile, canvas width = 80% of the screen, else 500px
            if (window.innerWidth < 768) {
                canvas.width = window.innerWidth * 0.8;
                canvas.height = window.innerHeight / 4;
            } else {
                canvas.width = 500;
                canvas.height = 200;
            }
            this.context = canvas.getContext('2d');
        },
        startDrawing() {
            this.drawing = true;
            if (!this.signature) {
                this.signature = true;
            }

            this.context.beginPath();
        },
        draw(event) {
            if (!this.drawing || this.sending) return;
            this.context.lineTo(
                event.pageX - event.target.getBoundingClientRect().left,
                event.pageY - event.target.getBoundingClientRect().top
            );
            this.context.stroke();
        },
        stopDrawing() {
            this.drawing = false;
        },
        deleteDrawing() {
            this.context.clearRect(0, 0, this.$refs.signatureCanvas.width, this.$refs.signatureCanvas.height);
            this.signature = false;
        },
        saveSignature() {
            this.sending = true;
            const dataURL = this.$refs.signatureCanvas.toDataURL();
            this.sendSignatureToServer(dataURL);
        },
        async sendSignatureToServer(dataURL) {
            const signature = await fetch(dataURL);
            const blob = await signature.blob();
            let data = new FormData();
            data.append('signature', blob);
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/videoconference/signature/' + this.visioUser.id, data)
                .then((response) => {
                    this.visioUser = response.data;
                    this.signature = false;
                    this.sending = false;
                    if (this.visioUser.signature && !this.tuteur) {
                        window.open(this.visio.url, '_blank');
                    }
                    if (this.tuteur) {
                        this.getSignatures();
                    }
                })
                .catch(() => {
                    this.error = true;
                    this.sending = false;
                });
        },
        startClassroom() {
            this.activatingSession = true;
            let data = {
                started: 1
            };
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/classroom/' + this.classroom.id, data).then(() => {
                this.$bvToast.toast('La session est maintenant accessible aux stagiaires.', {
                    title: 'Succès',
                    variant: 'success',
                    solid: true
                });
                this.getClassroom();
            });
        },
        getClassroom() {
            this.$store.dispatch(GET_CLASSROOM, this.$route.params.id).then((response) => {
                //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                if (this.tuteur) {
                    if (
                        response.data.tuteur_id !== this.$store.getters.currentUser.id &&
                        this.$store.getters.currentUser.role !== 'admin'
                    ) {
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        this.loading = false;
                    }
                } else {
                    this.loading = false;
                }
                this.classroom = response.data;
                this.classroomUpdate.id = this.classroom.id;
                this.classroomUpdate.visio_url = this.classroom.visio_url;
                this.key = this.key + 1;
                this.activatingSession = false;
            });
        }
    }
};
</script>
<style scoped>
.signature-pad {
    display: inline-block;
    border: 1px solid #000;
    cursor: crosshair;
}
</style>
